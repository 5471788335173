

@mixin operations-layer {
	z-index: 4;
	background-color: keys.$default-section-bgcolor;
	position: relative;
	background-size: 118%;
	background-repeat: no-repeat;
	background-position: -30px -50px;
	.operation-image {
		position: absolute;
		right: 5rem;
		top: 1rem;
		height: 100%;
	}
	.operation-card {
		padding-top: 3.5rem;
		padding-left: 3rem;
		h2 {
			font-size: 2rem;
			font-family: 'Nunito', sans-serif;
			font-weight: 600;
		}
		p {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			svg {
				height: 55px;
				width: 55px;
				margin-right: 0.5rem;
				color: keys.$header-bgcolor;
			}
			label {
				font-size: 1.7rem;
				line-height: 55px;
				font-family: 'Nexa', sans-serif;
			}
		}
		.control-button {
			@include blue-button;
		}
	}
}