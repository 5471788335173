

.control-button {
	display: inline-block;
	height: auto;
	width: auto;
	text-align: center;
	a {
		font-size: 0.9rem;
		font-weight: bold;
		font-family: "Lato", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
		width: 100%;
		display: block;
		text-decoration: none;
		color: white;
		text-shadow: 2px 1px rgba(0,0,0,0.5);
		padding: 12px 30px 12px 30px;
		border: solid 1px rgba(255,0,0,0.5);
		border-radius: 5px;
		transition: all 1s;
		background: rgba(255,0,0,0.2);
		
		&:visited,
		&:active {
			
		}

		&:hover {
			cursor: pointer;
			background: rgba(255,0,0,0.6);
			border: solid 1px rgba(255,0,0,0.8);
			color: white;
		}
	}
}