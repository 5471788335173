
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

@mixin header-styles {
	flex: 0 0 100%;
	padding-top: 0.8rem;
	padding-left: 3.5rem;
	h2 {
		font-family: "Nunito", sans-serif;
		font-weight: bold;
		font-size: 2rem;
		color: white;
		text-shadow: 0 0 1rem black;
	}
}

@mixin card-styles {
	flex: 0 0 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	padding: 1rem 20rem 3rem 20rem;
	input {
		flex: 0 0 80%;
		background-color: white;
		padding: 1rem;
		border-radius: 1rem;
		font-size: 1.2rem;
	}

	.control-button {
		flex: 0 0 20%;
		@include blue-button;
		margin: 0;
		a {
			border-radius: 1rem;
			font-size: 1rem;
			padding: 1.2rem 0rem;
		}
	}

}

.tracking-layer {
	flex: 0 0 100%;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 12rem;

	background-size: cover;
	background-position: fixed;
	background-repeat: no-repeat;

	.tracking-header {
		@include header-styles;
	}

	.tracking-card {
		@include card-styles;
	}

}