
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';


$rates-card-bg: rgba(255,255,255,1);
$rates-bg: $rates-card-bg;
$rates-card-border-color: rgba(173,170,170,1);

@mixin rates-content-layer {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 2rem 3rem;

	.rates-content-card {
		flex: 0 0 40%;
		height: auto;
		padding: 2rem 3rem;
		border: solid 1px $rates-card-border-color;
		background-color: $rates-card-bg;
		
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		figure {
			flex: 0 0 20%;
			text-align: center;
			img {
				width: 70px;
				height: 70px;
			}
		}
		.content {
			flex: 0 0 80%;
			width: 80;
			h2 {
				font-family: "Nunito", sans-serif;
				font-weight: bold;
				font-size: 1.1rem;
			}
			p {
				font-family: "Nexa", sans-serif;
				font-size: 1.1rem;
			}
		}
		.control-button {
			@include blue-button;
			a {
				padding: 1rem 2rem;
				font-size: 1.2rem;
			}
		}
	}
}

.rates-layer {
	flex: 0 0 100%;
	height: auto;
	margin-bottom: 3rem;
	background-color: $rates-bg;

	background-repeat: repeat-x;
	background-size: cover;
	background-attachment: fixed;
	background-position: -8rem 7rem;

	.rates-header {
		padding-top: 0.8rem;
		padding-left: 3.5rem;
		h2 {
			font-family: "Nunito", sans-serif;
			font-weight: 600;
			font-size: 2rem;
		}
	}
	.rates-content {
		@include rates-content-layer;
	}
}