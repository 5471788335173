@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

.header-navigator,
.header-navigator-sandwich {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	.navigator-item {
		display: flex;
		column-gap: 1.5rem;
		flex: 0 0 100%;
		a {
			flex: 0 0 auto;
			color: white;
			padding: 1rem 1.5rem;
			text-decoration: none;
			font-family: 'Nunito', sans-serif;
			font-size: 1.3rem;
			font-weight: 200;
			border-radius: 3px;
			border: 1px solid keys.$header-bgcolor;
			transition: all 0.3s linear;

			&:hover,
			&.active {
				border: 1px solid white;
				background-color: keys.$nav-item-bgcolor;
				color: keys.$nav-item-color;
			}
		}
	}
}
.header-navigator {
	&.hide {
		display: flex;
	}
}
.header-navigator-sandwich {
	display: none;
}