
@mixin we-layer {
	z-index: 2;
	background-color: keys.$default-section-bgcolor;
	height: auto;
	.section-item {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		figure,
		.content {
			padding: 4rem;
		}

		figure {
			flex: 0 0 50%;
			height: keys.$default-section-height;
			img {
				border-radius: 0.8rem;
				width: 510px;
				height: 320px;
				display: block;
				margin: auto;
				transition: transform 0.4s ease-in-out;
				&:hover {
					cursor: pointer;
					transform: scale(1.1);
				}
			}
		}
		.content {
			flex: 0 0 50%;
			h2 {
				font-size: 2rem;
				font-family: 'Nunito', sans-serif;
				font-weight: 600;
			}
			p {
				font-size: 1.2rem;
				font-family: 'Nexa', sans-serif;
			}
		}
	}
}