

@mixin proform-layer {
	z-index: 5;
	flex: 0 0 100%;
	background-color: keys.$services-bgcolor;
	height: auto;

	color: white;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	.proform-content {
		flex: 0 0 100%;
		padding-top: 3.5rem;
		padding-left: 3rem;
		h2 {
			font-size: 2rem;
			font-family: "Nunito", sans-serif;
			font-weight: 600;
		}
		p {
			font-size: 1.7rem;
			font-family: "Nexa", sans-serif;
			font-weight: bold;
		}
	}
	.proform-gallery {
		flex: 0 0 100%;

		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		article {
			flex: 0 0 auto;
			padding: 1rem 3rem 3rem 3rem;
			text-align: center;
			.control-button {
				margin-top: 2rem;
				a {
					padding: 1rem 3rem 1rem 3rem;
					border: solid 1px keys.$proform-button-bg;
					background-color: white;
					text-shadow: unset;
					color: keys.$proform-button-bg;
					font-size: 1.7rem;
					border-radius: 1.5rem;
					transition: all 0.3s linear;
 					&:hover {
						background-color: keys.$proform-button-bg-hover;
						border: solid 1px white;
						color: white;
						opacity: 0.9;
					}
				}
			}

			figure {
				overflow: hidden;
				height: 325px;
				width: 450px;
				img {
					transition: all 0.3s linear;
					width: 100%;
					height: 100%;
				}
			}
			p {
				margin: 0;
				padding-top: 1rem;
				padding-bottom: 1rem;
				background-color: keys.$proform-legend-bg;
				font-size: 1.7rem;
				text-align: center;
				font-family: "Nexa", sans-serif;
				font-weight: bold;
				transition: all 0.3s linear;
			}

			&:hover {
				cursor: pointer;
				p {
					background-color: keys.$proform-legend-bg-selected;
				}
				figure {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}