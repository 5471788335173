
@use 'src/media/styles/variables.scss' as keys;
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/nexa-bold');


$default-button-bg: keys.$header-bgcolor;
$default-button-bg-selected: rgba(2,42,89,1);
@mixin blue-button {
	margin-top: 1rem;
	margin-right: 1rem;
	a {
		padding: 1.5rem 2.5rem;
		border-radius: 1.5rem;
		border: solid 1px black;
		font-size: 1.5rem;
		font-family: "Nexa", sans-serif;
		text-shadow: 2px 1px none;
		background-color: $default-button-bg;
		color: white;
		&:hover {
			background-color: $default-button-bg-selected;
		}
	}
}


@mixin enable-flex {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}