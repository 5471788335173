@use 'src/media/styles/variables.scss' as keys;

.slider-layer {
	z-index: -1;
	flex: 0 0 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	height: 580px;
	z-index: auto;
	overflow: hidden;
	.carousel-item {
		flex: 0 0 100%;
		margin-top: 130px;
	}
}