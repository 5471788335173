
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$proform-title-color:  rgba(70,71,73,1);
$proform-input-border-color: rgba(0, 0, 0, 0.6);

@mixin button-styles {
	.control-button {
		@include blue-button;
		margin: 0 auto;
		a {
			font-size: 1.4rem;
			padding: 0.8rem 2.5rem;
		}
	}
}

@mixin input-styles {
	
	h2 {
		flex: 0 0 100%;
		font-size: 2.2rem;
		font-family: "Nexa", sans-serif;
		font-weight: bold;
		text-align: center;
		color: $proform-title-color;
		margin: 0 0 0 0;
		.form-icon {
			font-size: 3rem;
			margin-right: 1.5rem;
		}
	}

	input,
	textarea {
		background-color: white;
		margin: 0.9rem 0 0.9rem 0;
		padding: 1.1rem;
		border: solid 1px $proform-input-border-color;
		&:active,
		&:focus,
		&:hover {
			background: white;
		}
	}

	div {
		&.form-item-name,
		&.form-item-lastname,
		&.form-item-phone,
		&.form-item-email {
			flex: 0 0 48%;
		}
		&.form-item {
			flex: 0 0 100%;
		}
	}

	span {
		.form-icon {
			padding-right: 0.3rem;
		}
		&.error {
			padding: 0 0.3rem 0 0.3rem;
			border-radius: 2rem;
			color: rgba(255,70,70,1);
		}
	}
}

.proform-form-layer {
	@include enable-flex;
	height: auto;
	width: 100%;
	padding-top: 5rem;
	padding-bottom: 5rem;

	background-repeat: no-repeat-x;
	background-size: cover;
	background-attachment: fixed;
	opacity: 0.8;

	form {
		flex: 0 0 60%;
		background-color: white;
		padding: 2rem 5rem;
		border-radius: 3rem;

		@include enable-flex;
		justify-content: space-between;

		@include input-styles;
		@include button-styles;
	}
}