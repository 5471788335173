

$logo-mobile-width: 310px;
$proform-image-mobile-width: 370px;
$we-image-width: $proform-image-mobile-width;
$contact-image-mobile-width: $proform-image-mobile-width;
$location-iframe-width: $proform-image-mobile-width;


@media only screen and (max-width: 1200px) {

	.main-layer {
		.main-we {
			.section-item {
				.content,
				figure {
					transition: 1s padding ease-in-out;
					padding: 2rem 2rem;
				}
			}
		}
	}

	.header-layer {
		.header-navigator {
			.navigator-item {
				transition: column-gap 0.8s ease-in-out;
				column-gap: 0.6rem;
				a {
					transition: padding-left 0.8s ease-in-out,
								padding-right 0.8s ease-in-out;
					padding-left: 0.5rem;
					padding-right: 0.5rem;
				}
			}
		}	
	}
	.main-layer {
		.main-operations {
			.operation-image {
				transition: opacity 1s ease-in-out;
				z-index: -1;
				opacity: 0.4;
			}
		}
	}
}

@media only screen and (max-width: 980px) {
	.header-layer {
		justify-content: space-between;
		flex-flow: row wrap;
		height: auto;
		.header-navigator-sandwich {
			display: flex;
		}
		.header-navigator {
			&.hide {
				display: none;
			}
			flex: 0 0 100%;
			padding-top: 0;
			.navigator-item {
				flex-flow: row wrap;
				a {
					flex: 0 0 100%;
					text-align: center;
					padding-left: 0rem;
					padding-right: 0rem;
				}
			}
		}	
	}

	.main-layer {
		.main-we {
			.section-item {
				.content {
					flex: 0 0 100%;
				}
			}
		}
		.main-location {
			.location-details {
				.location-details-item {
					flex: 0 0 100%;
					padding-left: 5rem;
					padding-right: 5rem;
				}
			}
		}
		.main-contact {
			.contact-form {
				flex: 0 0 100%;
				form {
					padding: 2.5rem;
				}
			}
		}
	}

	.proform-form-layer {
		form {
			flex: auto;
			margin: 0 1.5rem;
			padding-left: 2.5rem;
			padding-right: 2.5rem;
		}
	}

	.rates-layer {
		.rates-content {
			.rates-content-card {
				flex: 0 0 80%;
			}
		}
	}

	.footer-layer {
		.footer-social, .footer-contact {
			flex: 0 0 80%;
		}
	}
}

@media only screen and (max-width: 640px) {
	.header-layer {
		.header-logo {
			flex: 0 0 100%;
			transition: 1s text-align ease-in-out; 
			text-align: center;
			figure img {
				transition: 1s width ease-in-out; 
				width: $logo-mobile-width;
			}
		}
		.header-navigator-sandwich {
			flex: 0 0 100%;
			.navigator-item {
				a {
					text-align: center;
					flex: 0 0 100%;
				}
			}
		}
		.header-navigator {
			flex: 0 0 100%;
			.navigator-item {
				a {
					transition: 0.5s padding ease-in-out,
								0.5s font-size ease-in-out;
					padding: 1rem 0rem;
					font-size: 1.3rem;
					border: unset;
				}
			}
		}
	}

	.main-layer {
		.main-we {
			.section-item {
				.content,
				figure {
					transition: 1s padding ease-in-out;
					padding: 1rem 1rem;
				}
				figure {
					transition: 1s height ease-in-out,
								1s padding-bottom ease-in-out;
					height: auto;
					padding-bottom: 2rem;
					img {
						transition: 1s width ease-in-out,
									1s height ease-in-out;
						width: $we-image-width;
						height: auto;
					}
				}
			}
		}
		.main-services {
			.section-item {
				flex: 0 0 100%;
				padding: 1rem 0;
			}
		}
		.main-operations {
			transition: 0.5s margin-bottom ease-in-out,
						1 height ease-in-out;
			height: auto;
			margin-bottom: 1.5rem;
			.operation-card {
				transition: 1s padding-left ease-in-out;
				padding-left: 1rem;
			}
		}
		.main-proform {
			.proform-gallery {
				article {
					transition: 1s padding ease-in-out;
					padding: 1rem 1rem 3rem 1rem;
					figure {
						transition: 1s width ease-in-out,
									1s height ease-in-out;
						width: $proform-image-mobile-width;
						height: auto;
					}
				}
			}
			.proform-content {
				transition: 1s padding-left ease-in-out;
				padding-left: 1rem;
			}
		}
		.main-contact {
			.contact-content {
				transition: 1s padding-left ease-in-out;
				padding-left: 1rem;
			}
			.contact-image {
				img {
					transition: 1s width ease-in-out;
					width: $contact-image-mobile-width;
					height: auto;
				}
			}
			.contact-form {
				form {
					padding: 0.5rem 0.5rem;
					div {
						&.form-item-name,
						&.form-item-lastname,
						&.form-item-phone,
						&.form-item-email {
							flex: 0 0 100%
						}
					}
				}
			}
		}
		.main-location {
			.location-map {
				flex: 0 0 100%;
				iframe {
					transition: 1s width ease-in-out;
					width: $location-iframe-width;
				}
			}
			.location-details {
				flex: 0 0 100%;
				.location-details-item {
					transition: 1s padding-right ease-in-out,
								1s padding-left ease-in-out;
					padding-right: 1rem;
					padding-left: 1rem;
				}
			}
		}
	}

	.proform-form-layer {
		form {
			div {
				&.form-item-name,
				&.form-item-lastname,
				&.form-item-phone,
				&.form-item-email {
					flex: 0 0 100%;
				}
			}
		}
	}

	.footer-layer {
		.footer-social {
			flex: 0 0 100%;
			figure {
				img {
					transition: width 1s ease-in-out,
								height 1s ease-in-out;
					width: $logo-mobile-width;
					height: auto;
				}
			}
			span {
				a {
					transition: 1s margin ease-in-out,
								1s padding ease-in-out;
					margin: 0 0.2rem;
					padding: 0.8rem 0.8rem 0.5rem 0.8rem
				}
			}
		}
		.footer-contact {
			transition: padding 0.5s ease-in-out;
			padding: 0 1.5rem;
			flex: 0 0 100%;
			p {
				transition: 0.5s font-size ease-in-out;
				font-size: 1.3rem;
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	.main-layer {
		.slider-layer {
			display: none;
		}
		.main-we {
			.section-item {
				figure,
				.content {
					padding: 1rem 0.5rem;
					p {
						text-align: justify;
					}
				}
				figure {
					img {
						width: 320px;
					}
				}
			}
		}
		.main-services {
			.section-item {
				padding: 0.5rem 0;
			}
		}
		.main-proform {
			.proform-gallery {
				article {
					figure {
						width: 320px;
					}
				}
			}
		}
	}
}