


@mixin services-layer {
	z-index: 3;
	background-color: keys.$services-bgcolor;
	height: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	.section-item {
		flex: 0 0 33%;
		height: 100%;
		padding: 1rem 3.5rem;
		background-color: keys.$services-bgcolor;
		opacity: 0.9;
		position: relative;
		text-align: center;
		transition: 0.5s padding ease-in-out;
		
		.section-item-img {
			border: solid 1px white;
			display: block;
			width: $service-item-img-width;
			height: auto;
			border-radius: 50%;
			margin: 0 auto;
			overflow: hidden;
			svg {
				width: $service-item-svg-width;
				height: $service-item-svg-height;
				padding: 3rem 1.5rem;
				transition: 0.3s transform ease-in-out;
				&:hover {
					cursor: pointer;
					transform: scale(1.3);
				}
			}
		}

		.content {
			h2 {
				margin-top: 1rem;
				margin-bottom: 0.5rem;
				text-align: center;
				color: white;
				font-size: 1.5rem;
				font-family: 'Nunito', sans-serif;
				font-weight: 600;
			}
			p {
				margin-top: 0.7rem;
				color: white;
				font-size: 1.3rem;
				font-family: 'Nexa', sans-serif;
				text-align: center;
			}
		}

		.section-item-bg {
			z-index: -2;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			opacity: 0.1;
		}
	}
}