@use 'src/media/styles/variables.scss' as keys;

.header-layer {
	z-index: 1;
	flex: 0 0 100%;
	height: 120px;
	background-color: keys.$header-bgcolor;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-around;
	
	.header-logo,
	.header-navigator {
		padding: 25px 0;
	}
	.header-logo {
		flex: 0 0 auto;
		figure {
			// margin-left: -5rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
}