

@mixin button-styles {
	.control-button {
		@include blue-button;
		margin: 0.8rem auto auto auto;
		a {
			padding: 1rem 2.5rem;
			font-size: 1.4rem;
		}
	}
}


@mixin input-styles {
	.control-text,
	.control-textarea {
		background-color: white;
		margin: 0.5rem 0;
		&[name ~= "message" ] {
			height: 120px;
		}

		svg { width: auto;}
	}

	div {
		&.form-item-name,
		&.form-item-lastname,
		&.form-item-phone,
		&.form-item-email {
			flex: 0 0 48%;
		}
		&.form-item {
			flex: 0 0 100%;
		}
	}

	span {
		.form-icon {
			padding-right: 0.3rem;
		}
		&.error {
			padding: 0 0.3rem 0 0.3rem;
			border-radius: 2rem;
			color: rgba(255,70,70,1);
		}
	}
}

@mixin contact-layer {
	z-index: 7;
	flex: 0 0 100%;
	background-color: keys.$default-section-bgcolor;
	height: auto;

	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 2rem;
	.contact-content {
		flex: 0 0 100%;
		padding-top: 2.5rem;
		padding-left: 3rem;
		h2 {
			font-size: 2rem;
			font-family: "Nunito", sans-serif;
			font-weight: 600;
		}
	}
	.contact-image {
		flex: 0 0 50%;
		img {
			display: block;
			margin: auto;
			width: 500px;
			height: 500px;
		}
	}
	.contact-form {
		flex: 0 0 50%;
		height: auto;
		form {
			padding: 0.5rem 1.5rem;
			@include enable-flex;
			justify-content: space-between;
			// column-gap: 40px;
			p {
				font-size: 1.3rem;
				font-family: "Nexa", sans-serif;
			}

			@include input-styles;
			@include button-styles;
		}
	}
}