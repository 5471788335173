
$header-bgcolor: rgba(0,66,140,1);
$nav-item-color: $header-bgcolor;
$nav-item-bgcolor: rgba(204,217,232,1);
$services-bgcolor: $header-bgcolor;
$default-section-height: 450px;
$default-section-bgcolor: rgba(242,243,246,0.7);

$proform-legend-bg: rgba(5,77,159,1);
$proform-legend-bg-selected: rgba(5,95,198,1);
$proform-button-bg: rgba(51,51,52,1);
$proform-button-bg-hover: rgba(51,51,52,0.5);