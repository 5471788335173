
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

@import 'src/containers/MainContainer/MainWeContainer.scss';
@import 'src/containers/MainContainer/MainServicesContainer.scss';
@import 'src/containers/MainContainer/MainOperationsContainer.scss';
@import 'src/containers/MainContainer/MainProformContainer.scss';
@import 'src/containers/MainContainer/MainContactContainer.scss';
@import 'src/containers/MainContainer/MainLocationContainer.scss';


$icon-color: rgba(1,65,140);
$location-item-width: 930px;

$service-item-img-width: 250px;
$service-item-svg-width: 180px;
$service-item-svg-height: 200px;

.main-layer {
	height: auto;
	flex: 0 0 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;

	.main-we,
	.main-services,
	.main-operations,
	.main-proform {
		flex: 0 0 100%;
		height: keys.$default-section-height;
	}

	.main-we {
		@include we-layer;
	}
	.main-services {
		@include services-layer;
	}
	.main-operations {
		@include operations-layer;
	}
	.main-proform {
		@include proform-layer;
	}
	.main-location {
		@include location-layer;
	}
	.main-contact {
		@include contact-layer;
	}
}