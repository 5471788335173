
@mixin location-layer {
	z-index: 6;
	flex: 0 0 100%;
	background-color: keys.$default-section-bgcolor;
	height: auto;
	padding: 2rem 0 2rem 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	.location-map,
	.location-details {
		flex: 0 0 50%;
		height: auto;
	}

	.location-map {
		iframe {
			margin: auto;
			display: block;
			width: 600px;
			height: 420px;
		}
	}
	.location-details {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		
		.location-details-item {
			flex: 0 0 $location-item-width;
			padding-left: 10rem;
			padding-right: 10rem;
			padding-bottom: 1rem;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			align-content: flex-start;
			position: relative;

			&:last-child {
				hr { display: none; }
			}
			hr {
				margin-top: 1.2rem;
				width: 100%;
				color: $icon-color;
			}
			svg {
				flex: 0 0 20%;
				font-size: 2.5rem;
				color: $icon-color;
			}
			span {
				flex: 0 0 80%;
				h2 {
					font-family: 'Nunito', sans-serif;
					font-weight: 600;
					font-size: 1.6rem;
					margin: 0 0 0 0;
				}
				p {
					margin: 0;
					font-family: 'Nunito', sans-serif;
					font-weight: 600;
					font-size: 1.2rem;	
				}
			}
		}
	}
}