
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

.footer-layer {
	flex: 0 0 100%;
	height: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	background-color: keys.$header-bgcolor;
	color: white;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: -8rem 20rem;
	background-attachment: fixed;

	.footer-social,
	.footer-contact {
		flex: 0 0 50%;
	}

	.footer-social {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		
		figure {
			flex: 0 0 100%;
			margin-bottom: 1rem;
			img {
				width: 450px;
				height: 100px;
				display: block;
				margin: auto;
			}
		}
		span {
			flex: 0 0 100%;
			text-align: center;
			label, a {
				line-height: 3rem;
				font-size: 1.5rem;
				font-family: 'Nexa', sans-serif;
				font-weight: bold;
			}
			a {
				padding: 1.1rem 1.1rem 0.8rem 1.1rem;
				margin: 0 1rem 0 1rem;
				border-radius: 50%;
				text-decoration: none;
				color: keys.$header-bgcolor;
				background-color: white;
				transition: all 0.2s linear;
				&:hover {
					cursor: pointer;
					font-size: 2.2rem;
				}
			}
		}
	}

	.footer-contact {
		h2 {
			font-size: 1.6rem;
			font-family: 'Nunito', sans-serif;
			font-weight: 600;
		}
		p {
			font-size: 1.3rem;
			font-family: 'Nexa', sans-serif;
		}
	}
}
