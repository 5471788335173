
.control-text-icon {
	margin: 20px 0;
	display: block;
	padding: 10px 0px;
	border: solid 1px rgba(192,192,192,1);
	background: rgba(192,192,192,0.3);
	overflow: hidden;
	border-radius: 5px;
	color: black;
	transition: all 0.8s ease;
}
.control-text-icon:hover {
	background: rgba(0,0,0,0.1);
	border: solid 1px rgba(0,0,0,0.6);
}
.control-text-icon svg,
.control-text-icon input {
	float: left;
	font-family: "Lato", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
	font-size: 1.1rem;
	transition: all 1s;
}
.control-text-icon svg {
	display: block;
	width: 4%;
	padding-top: 2px;
}
.control-text-icon input {
	display: block;
	width: 95%;
	outline: none;
	border: none;
	background: transparent;
}

.control-text,
.control-textarea {
	padding: 10px;
	display: block;
	width: 100%;
	margin: 20px 0px 20px 0;
	border-radius: 5px;
	font-family: "Lato", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
	font-size: 1.1rem;
	color: black;
	outline: none;
	transition: all 0.8s ease;
	border: solid 1px rgba(192,192,192,1);
	background:  rgba(192,192,192,0.3);
}
.control-text:focus,
.control-textarea:focus {
	background: rgba(0,0,0,0.1);
	border: solid 1px rgba(0,0,0,0.6);
}